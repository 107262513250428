import { memo, useMemo } from 'react';

//* HOC's
import { withLanguageContext } from '@/context';

//* Components
import Icon from '../Icon';
import CustomLink from '../CustomLink';

//* Style
import ButtonStyle from './style';

const Button = memo(({ btnType, text, iconName, className, onClick, url, translate, type = 'button', disabled = false, children, isRightLeftAnim = false, external }) => {
	const Component = useMemo(() => (url ? CustomLink : 'button'), [url]);

	const customProps = useMemo(() => {
		if (url) {
			return {
				url,
				text,
				external,
			};
		} else {
			return { type, disabled };
		}
	}, [url, text, external, type, disabled]);

	let btn;

	switch (btnType) {
		case 'icon':
			btn = (
				<ButtonStyle
					onClick={onClick || null}
					className={`btn-icon ${disabled ? 'disabled' : ''} ${className || ''}`}>
					<Component {...customProps}>
						<Icon name={iconName} />
					</Component>
				</ButtonStyle>
			);

			break;

		default:
			btn = (
				<ButtonStyle
					onClick={onClick || null}
					className={`btn-text ${disabled ? 'disabled' : ''} ${className || ''}`}>
					<Component {...customProps}>{children || translate(text)}</Component>
				</ButtonStyle>
			);
	}

	return btn;
});

export default withLanguageContext(Button, ['translate']);
