import styled from 'styled-components';

const ButtonStyle = styled.div`
	--defaultBtnPadTB: var(--sp3x);
	--defaultBtnPadLR: var(--sp4x);

	--iconBtnSize: var(--sp9x);

	&.btn-text {
		width: fit-content;

		a,
		button {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-family: var(--avenir);
			font-weight: 400;
			font-size: var(--p6);
			line-height: var(--lineHeightL);
			text-transform: uppercase;
			border-radius: var(--sp1x);
			cursor: pointer;
			position: relative;
			overflow: hidden;
			isolation: isolate;
			height: var(--iconBtnSize);
			padding: 0 var(--defaultBtnPadLR);
		}

		&.primary {
			a,
			button {
				background-color: var(--white1000);
				color: var(--royalBlue1000);
				border: 1px solid var(--white1000);
				transition: var(--trTime);
			}

			&.inverse {
				a,
				button {
					background-color: var(--white1000);
					color: var(--royalBlue1000);
					border: 1px solid var(--white1000);
				}
			}

			@media (hover: hover) {
				&:hover {
					a,
					button {
						background-color: var(--royalBlue1000);
						color: var(--white1000);
						border: 1px solid var(--royalBlue1000);
					}

					&.inverse {
						a,
						button {
							background-color: var(--royalBlue200);
							color: var(--royalBlue1000);
							border: 1px solid var(--royalBlue200);
						}
					}
				}
			}
		}

		&.secondary {
			a,
			button {
				background-color: transparent;
				color: var(--royalBlue1000);
				border: 1px solid var(--royalBlue1000);
				transition: var(--trTime);
			}

			&.inverse {
				a,
				button {
					color: var(--white1000);
					border: 1px solid var(--white1000);
				}
			}

			@media (hover: hover) {
				&:hover {
					a,
					button {
						background-color: var(--royalBlue1000);
						color: var(--white1000);
						border: 1px solid var(--royalBlue1000);
					}

					&.inverse {
						a,
						button {
							background-color: var(--white1000);
							color: var(--royalBlue1000);
							border: 1px solid var(--white1000);
						}
					}
				}
			}
		}

		&.territory {
			a,
			button {
				padding: 0;
				border-radius: 0;
				background-color: unset;
				color: var(--royalBlue1000);
				border: unset;
			}

			&.inverse {
				a,
				button {
					color: var(--white1000);
				}
			}
		}
	}

	/* //! ICON VERSION */
	&.btn-icon {
		width: fit-content;

		a,
		button {
			display: flex;
			align-items: center;
			justify-content: center;
			width: var(--iconBtnSize);
			height: var(--iconBtnSize);
			text-transform: uppercase;
			border-radius: var(--sp1x);
			cursor: pointer;
			position: relative;
			overflow: hidden;
			isolation: isolate;
			transition: var(--trTime);

			i {
				font-size: var(--p3);
				line-height: 1;

				&:before {
					font-size: var(--p3);
					transition: var(--trTime);
				}
			}
		}

		&.primary {
			a,
			button {
				background-color: var(--white1000);
				border: 1px solid var(--white1000);

				i {
					&:before {
						color: var(--royalBlue1000);
					}
				}
			}

			@media (hover: hover) {
				&:hover {
					a,
					button {
						background-color: var(--royalBlue1000);
						border: 1px solid var(--royalBlue1000);

						i {
							&::before {
								color: var(--white1000);
							}
						}
					}
				}
			}
		}

		&.secondary {
			a,
			button {
				background-color: transparent;
				border: 1px solid var(--royalBlue1000);

				i {
					&:before {
						color: var(--royalBlue1000);
					}
				}
			}

			&.inverse {
				a,
				button {
					border: 1px solid var(--white1000);

					i {
						&:before {
							color: var(--white1000);
						}
					}
				}
			}

			@media (hover: hover) {
				&:hover {
					a,
					button {
						background-color: var(--royalBlue1000);
						border: 1px solid var(--royalBlue1000);

						i {
							&::before {
								color: var(--white1000);
							}
						}
					}

					&.inverse {
						a,
						button {
							background-color: var(--white1000);
							border: 1px solid var(--white1000);

							i {
								&::before {
									color: var(--royalBlue1000);
								}
							}
						}
					}
				}
			}
		}

		&.territory {
			a,
			button {
				padding: 0;
				border-radius: 0;
				background-color: unset;
				border: unset;

				i {
					&:before {
						color: var(--royalBlue1000);
					}
				}
			}

			&.inverse {
				a,
				button {
					i {
						&:before {
							color: var(--white1000);
						}
					}
				}
			}
		}
	}

	/* //! GLOBAL DISABLED */
	&.disabled {
		pointer-events: none;

		a,
		button {
			opacity: 0.5;
			cursor: default;
		}
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--defaultBtnPadTB: var(--sp2-5x);
		--defaultBtnPadLR: var(--sp3x);

		--iconBtnSize: var(--sp7x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--defaultBtnPadTB: var(--sp2-5x);
		--defaultBtnPadLR: var(--sp3x);

		--iconBtnSize: var(--sp7x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--defaultBtnPadTB: var(--sp2-5x);
		--defaultBtnPadLR: var(--sp2-5x);

		--iconBtnSize: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--defaultBtnPadTB: var(--sp2-5x);
		--defaultBtnPadLR: var(--sp2-5x);

		--iconBtnSize: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--defaultBtnPadTB: var(--sp2-5x);
		--defaultBtnPadLR: var(--sp2-5x);

		--iconBtnSize: var(--sp6x);
	}

	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--defaultBtnPadTB: var(--sp2-5x);
		--defaultBtnPadLR: var(--sp2-5x);

		--iconBtnSize: var(--sp5x);
	}
`;
export default ButtonStyle;
