'use client';

//* Style
import Custom404Style from '@/styles/pages/Custom404Style';

//* Helper's
import { config } from '@/helpers';

//* Components
import Page from '@/components/global/Page';
import Text from '@/components/global/Text';
import Container from '@/components/global/Container';
import Button from '@/components/global/Button';

export default function NotFound() {

    return (
        <Page className={`not-found`}>
            <Custom404Style>
                <Container
                    row
                    className={'error-container'}>
                        <div className='col-2 col-t-12 empty-col'></div>

                        <div className='col-8 col-t-12'>
                        <Text
                    className={'h2 font-argent letter-spacing-h2 title'}
                    text={'404title'}
                    />
                    <Text
                    className={'p p6 font-avenir-regular description'}
                    text={'404description'}
                    />
                    <Button
                    url={config.routes.home.path}
                    className={'primary to-home'}
                    text={'goToHomepage'}
                    />
                    </div>
                </Container>
             </Custom404Style>
        </Page>
    );
}