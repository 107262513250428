import styled from 'styled-components';

const Custom404Style = styled.div`
	--descriptionMarginTopBottom: var(--sp4x) 0 var(--sp7x) 0;

	height: calc(100vh - var(--headerSpacing));

	.error-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: calc(100% - var(--headerSpacing));

		.description {
			text-align: center;
            margin: var(--descriptionMarginTopBottom);
		}

		.title {
			text-align: center;
		}

		.to-home {
			margin-left: auto;
			margin-right: auto;
		}
	}

	//! 1920
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeXLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeL}) {
		--descriptionMarginTopBottom: var(--sp3x) 0 var(--sp5x) 0;
	}

	//! 1536
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeLMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeM}) {
		--descriptionMarginTopBottom: var(--sp3x) 0 var(--sp5x) 0;
	}

	//! 1366
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeMMin}) and (min-width: ${(props) => props.theme.mediaQuery.desktopSizeS}) {
		--descriptionMarginTopBottom: var(--sp3x) 0 var(--sp4x) 0;
	}

	//! 1280
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.desktopSizeSMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSize}) {
		--descriptionMarginTopBottom: var(--sp3x) 0 var(--sp4x) 0;
	}

	//! 768
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeMin}) and (min-width: ${(props) => props.theme.mediaQuery.tabletSizeS}) {
		--descriptionMarginTopBottom: var(--sp3x) 0;
	}

	//! Mobile
	@media only screen and (max-width: ${(props) => props.theme.mediaQuery.tabletSizeSMin}) {
		--descriptionMarginTopBottom: var(--sp2x) 0 var(--sp3x) 0;
	}
`;

export default Custom404Style;
